<template>
  <footer class="py-4 text-center text-slate-500">
    <p class="text-sm">
      Zero Bypass Limited
    </p>
    <p class="text-xs mt-1">
      Copyright © {{ new Date().getFullYear() }}
    </p>
  </footer>
</template>
